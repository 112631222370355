<template>
  <div>
    <h2>Historical Crowd Size</h2>
    <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
      <button type="button" class="btn btn-primary" v-on:click="change_chart_data('hour')">Hour</button>
      <button type="button" class="btn btn-primary" v-on:click="change_chart_data('day')">Day</button>
      <button type="button" class="btn btn-primary" v-on:click="change_chart_data('week')">Week</button>
      <button type="button" class="btn btn-primary" v-on:click="change_chart_data('month')">Month</button>
    </div>

    <apexchart
      type="line"
      :options="chartOptions"
      :series="series"
    ></apexchart>

  </div>
</template>
<script>
import $ from 'jquery';
import VueApexCharts from "vue3-apexcharts";

export default {
    name: "CrowdChart",
    components: {
      apexchart: VueApexCharts,
    },
  props: {
    location: String
  },
  data () {
    return {
      timeseries_type: "hour",
      chartOptions: {
        chart: {
            type: "line",
            id: "Historical Crowd Data",
            zoom: {
                enabled: false
            }
        },
        // xaxis: {
        //   categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        // },
      },
      series: [
        {
          name: "Crowd Data",
          data: [30, 40, 35, 50, 49, 60, 70, 91],
        },
      ],
    }
  },
  methods: {
    change_chart_data: function (range_type){
      // Range type should be: hour | day | week | month
      this.timeseries_type = range_type
      console.log(range_type)

      this.query_data()
    },

    query_data: function() {
      var url =  "https://api.metrofo.com/crowd_data/" + this.location + "/" + this.timeseries_type;

      $.get(url, function (response) {
        this.series = [
          { data: response["value"] },
        ]
        console.log("data fetched")
        console.log(this.timeseries)

      }.bind(this));
    },

  },
  mounted () {
    this.query_data()

    setInterval(function () {
      console.log("interval")
      this.query_data();
    }.bind(this), 10000); 
  },
  beforeUnmount: function(){
    clearInterval(this.interval);
  }

}


</script>