<template>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
  </head>
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <Header></Header>
  <body class="bg-light">
      <Station station_name="Tysons Corner"></Station>
  </body>
</template>

<script>
import Station from './components/Station.vue';
import Header from './components/Header.vue'

export default {
  name: 'App',
  created () {
    document.title = "MetroFo";
  },
  components: {
    Station,
    Header
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

@import'./css/bootstrap.min.css'
</style>
