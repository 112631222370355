<template>
    <table class="table">
        <thead>
            <tr>
            <th scope="col">Time to Leave</th>
            <th scope="col">Predicted Crowd Size</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th scope="row">In the next 15 minutes:</th>
                <td>{{ min_15 }}</td>
            </tr>
            <tr>
                <th scope="row">In the next half hour:</th>
                <td> {{ min_30 }}</td>
            </tr>
            <tr>
                <th scope="row">In the next hour:</th>
                <td> {{ min_60 }}</td>
            </tr>
        </tbody>
    </table>

</template>

<script>
import $ from 'jquery';

export default {
    name: "PredictionTable",

    props: {
        refresh: {
            default: 2000,
            type: Number
        },
        location: String
    },
    data() {
        return {
            min_15: 0,
            min_30: 0,
            min_60: 0
        }
    },

    methods: {
    loadData: function () {
        console.log("Hello world")
        var url =  "https://api.metrofo.com/crowd_data/" + this.location + "/prediction";
        $.get(url, function (response) {
            console.log(response)
            this.min_15 = response["value"]["min_15"]
            this.min_30 = response["value"]["min_30"]
            this.min_60 = response["value"]["min_60"]
        }.bind(this));
    }
    },
    mounted: function () {
    console.log("hello interval")
    this.loadData();

    setInterval(function () {
        console.log("interval")
        this.loadData();
    }.bind(this), this.refresh); 
    },
    beforeUnmount: function(){
    clearInterval(this.interval);
    }

}
</script>
