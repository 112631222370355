<template>

    <div class="container bg-primary text-white m-0 p-0" style="max-width: unset;">
        <div class="row">
            <div class="col-sm"/>
            <div class="col-sm">
            <h1>District of Columbia</h1>
            </div>
            <div class="col-sm"/>
        </div>
    </div>

    <div class="container">
        <div class="row bg-light">
            <div class="col-sm-6">
                <h1>{{ station_name }} Metro Station</h1>
                <img src="../assets/metro-station.jpg" height=300 width=300 class="img-fluid img-thumbnail" style="border-radius: 2%;">
            <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        What is this website?
                    </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">This site is designed to help you avoid the crowds at the Metro!</div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        How often does it update?
                    </button>
                    </h2>
                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Our data updates every five minutes.</div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        How does it work?
                    </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">We use state of the art AI to analyze metro conditions to estimate how many people are at a metro station.</div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                        How do I use it?
                    </button>
                    </h2>
                    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <ol>
                            <li>The crowd meter tells you in real time how many people are likely at the metro station.</li>
                            <li>Our timetable tells you how many people should be at the metro station in the coming hour. We use regression techniques to estimate this. </li>
                            <li>We have provided a chart that shows you all the recorded foot traffic for the past year.</li>
                        </ol>
                            </div>
                    </div>
                </div>
            </div>
            
            </div>
            <div class="col-sm-6">
                <h3>Crowd Meter</h3>
                <h4><CrowdCount location="tysons_corner" refresh=10000></CrowdCount></h4>

                <hr/>

                <h3>Check Pedestrian Traffic for Best Time to Leave</h3>
                <PredictionTable location="tysons_corner" refresh=10000></PredictionTable>
            </div>
        </div>
        <div class="row">

            <!-- <div class="col">
                <div class="row">
                    <h3>Next Train</h3>
                </div>
            </div>
        </div>
        <div class="row"> -->
            <div class="col-sm-6">

            </div>
        </div>
        <hr/>
        <div class="row">
            <CrowdChart location="tysons_corner"/>
        </div>
    </div>
</template>

<script>

import CrowdCount from './Counter.vue'
import PredictionTable from './PredictionTable.vue'
import CrowdChart from './CrowdChart.vue'

export default {
    name: "Station",
    props:{
        station_name: String
    },
    components: {
        CrowdCount,
        PredictionTable,
        CrowdChart
    }
}
</script>
