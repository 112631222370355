<template>
  <header class=" text-white">
    <nav class="navbar navbar-expand-lg navbar-light bg-secondary">
        <a href="/" class="d-flex text-white text-decoration-none">
          <img src="../assets/metrofo-logo.png" width="100">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav">
            <a href="#" class="nav-item nav-link text-white active">Home</a>
            <a href="#" class="nav-item nav-link px-2 text-white disabled">Stations</a>
            <a href="#" class="nav-item nav-link px-2 text-white disabled">FAQs</a>
            <a href="#" class="nav-item nav-link px-2 text-white disabled">About</a>
          </div>
        </div>
    </nav>
  </header>
</template>

<script>

export default {
    name: "Header"
}
</script>
